import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import customizationReducer from './customizationReducer'
import snackbarReducer from './snackbarReducer'
import cartReducer from './cartReducer'
import kanbanReducer from './kanbanReducer'
import pageReducer from './pageReducer'
import templateReducer from './templateReducer'
import formReducer from './formReducer'
import listingReducer from './listingReducer'
import customerReducer from './customerReducer'
import tagReducer from './tagReducer'
import usersReducer from './usersReducer'
import spaceReducer from './spaceReducer'
import assetReducer from './assetReducer'
import spaceAnalyticReducer from './spaceAnalyticReducer'
import componentReducer from './componentReducer'
import exclusiveReducer from './exclusiveReducer'
import membersReducer from './membersReducer'
import headerReducer from './headerReducer'
import footerReducer from './footerReducer'
import sidebarReducer from './sidebarReducer'
import spacesReducer from './spacesReducer'
import spaceTemplateReducer from './spaceTemplateReducer'

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    page: pageReducer,
    template : templateReducer,
    form : formReducer,
    space: spaceReducer,
    tag: tagReducer,
    listing: listingReducer,
    customer: customerReducer,
    users: usersReducer,
    asset: assetReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-',
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    spaceAnalytic: spaceAnalyticReducer,
    spaces: spacesReducer,
    component: componentReducer,
    exclusive: exclusiveReducer,
    member: membersReducer,
    header: headerReducer,
    footer: footerReducer,
    sidebar: sidebarReducer,
    spaceTemplate: spaceTemplateReducer
})

export default reducer
