import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, MenuItem, ListSubheader, Select, InputAdornment } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useSpace from 'src/hooks/useSpace';
import Rocket from 'src/assets/images/rocket.svg';

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
    backgroundColor: '#fbfbfb',
    '&:hover': {
        backgroundColor: '#fff'
    }
}));

const SpaceNameStyle = styled('span')(({ theme }) => ({
    color: '#1F2047',
    fontWeight: 500,
    lineHeight: '12px',
    fontSize: '12px'
}));

const SpaceRoleStyle = styled('span')(({ theme }) => ({
    color: '#1F2047',
    fontWeight: 400,
    lineHeight: '12px',
    paddingTop: '2px',
    fontSize: '8px'
}));

const SpaceSelection = () => {
    const { user } = useAuth();
    const [space, setSpace] = useSpace();
    const [options, setOptions] = React.useState<{ id: string, name: string, plans: { id: string, name: string, user_role: string }[] }[]>([]);

    React.useEffect(() => {
        if (!user) return;
        const options = [
            {
                id: 'personal_account',
                name: '',
                plans: [{ id: 'user', name: `${user.name}`, user_role: 'Personal' }]
            },
            {
                id: 'spaces',
                name: 'Spaces',
                plans: [
                    ...user.personalSpaces.map(({ id, name, user_role }: { id: string, name: string, user_role: string }) => ({ id, name, user_role })),
                    ...user.contributedSpaces.map(({ id, name, user_role }: { id: string, name: string, user_role: string }) => ({ id, name, user_role }))
                ]
            }
        ];
        setOptions(options);
    }, [user]);

    const handleChange = (event: any) => {
        console.log(event.target.value);
        if (!event.target.value || event.target.value === 'user') setSpace(null);
        else setSpace(event.target.value as string);
    };

    const renderSelectGroup = (product: { id: string, name: string, plans: { id: string, name: string, user_role: string }[] }) => {
        const items = product.plans.map((p) => (
            <MenuItemStyle key={p.id} value={p.id}>
                <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                    <SpaceNameStyle>{p.name}</SpaceNameStyle>
                    <SpaceRoleStyle>{p.user_role}</SpaceRoleStyle>
                </div>
            </MenuItemStyle>
        ));
        return [<ListSubheader key={product.id} style={{ lineHeight: '32px', fontWeight: 400, fontSize: '14px' }} >{product.name}</ListSubheader>, items];
    };

    return (
        <>
            <Box>
                <Select
                    fullWidth
                    value={space?.id || 'user'}
                    onChange={handleChange}
                    sx={{
                        border: '1px solid #eeeeee',
                        borderRadius: '8px',
                        width: 220,
                        p: 0,
                        backgroundColor: 'white',
                        // backgroundColor: '#eee !important',
                        // '&:hover': {
                            // backgroundColor: '#fff'
                        // },
                        '& .MuiSvgIcon-root': {
                            borderRadius: '50%',
                            border: '1px solid #eaeaea'
                        },  
                        '&:hover .MuiSvgIcon-root': {
                            borderRadius: '50%',
                            backgroundColor: '#eaeaea'
                        },
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '.MuiOutlinedInput-input': { fontWeight: 'bold' }
                    }}
                    startAdornment={
                        <InputAdornment position="start" sx={{
                            // backgroundColor: '#eee',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 0
                        }} >
                            <img src={space?.meta?.favicon_url ? space.meta.favicon_url : Rocket} alt="hyzebra" width="30px" height="30px" />
                        </InputAdornment>
                    }
                    MenuProps={{
                        sx: {
                            '& .MuiPaper-root': {
                                width: 220,
                                borderRadius: '6px',
                                top: '74px !important',
                                left: '12px !important'
                            }
                        }
                    }}
                >
                    {options && options.map((p) => renderSelectGroup(p))}
                </Select>
            </Box >
        </>
    );
};

export default SpaceSelection;
